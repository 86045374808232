export default {
	data: {

	},
	beforeMount: function () {

	},
	mounted: function () {
		var numItems = $('.offer-blog-item').length;
		console.log('mounted offer items:' + numItems);
		if (numItems <= 0) {
			$("#messagetext").show();
		} else {
			$("#messagetext").hide();
		}

	},

	updated: function () {

	}
};